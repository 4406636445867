@use "../../utilities/variables/text/fonts";
@use "../../utilities/variables/spacing";
@use "../../utilities/variables/color/neutral";
@use "../../utilities/variables/color/accent";
@use "../../utilities/mixins";

.text-with-icon {
  $base: &;

  display: flex;
  align-items: center;

  &--l {
    margin: spacing.$spacing-m 0;

    #{$base}__icon {
      margin-right: spacing.$spacing-s;
    }
  }

  &__icon {
    margin-right: spacing.$spacing-xxs;
  }

  &__text {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--vertical {
    flex-direction: column;
    text-align: center;
    margin: 0;

    #{$base}__icon {
      margin-right: 0;
    }
  }

  &--clickable {
    @include mixins.button-color-interaction();
  }

  &--secondary {
    @include mixins.text-with-svg-color(neutral.$neutral_04);
  }

  &--icon-secondary {
    @include mixins.svg-fill(neutral.$neutral_04);
  }

  &--disabled {
    @include mixins.button-disabled();
  }

  &--checked {
    @include mixins.text-with-svg-color(accent.$accent_05);
  }
}
