@use "../utilities/variables/color/neutral";
@use "../utilities/variables/color/accent";
@use "../utilities/mixins";
@use "../utilities/functions" as utils;
@use "../utilities/variables/spacing";
@use "../utilities/variables/dimentions";

//reset style for when a button is used as a container with no styling of its own
button,
.btn {
  overflow: hidden;

  &:focus,
  &:active:focus {
    box-shadow: none;
  }

  &.is-container {
    @include mixins.button-as-container(false);
    box-shadow: none;
  }

  .icon {
    @include mixins.size(16px);
  }
}

.btn {
  $base: &;

  @include mixins.button-color-interaction();

  svg {
    path {
      @include mixins.transition(fill, 0.15s, ease-in-out);
    }
  }

  &:disabled {
    background-color: neutral.$neutral_03;
  }

  // button with text
  &:not(.as-icon):not(.is-container) {
    height: dimentions.$input-height;

    &#{$base}-tertiary {
      height: dimentions.$input-height-small;
    }
  }

  // round button
  &.as-icon {
    @include mixins.size(dimentions.$input-height);
    padding: spacing.$spacing-xs;

    .icon {
      $icon-size: 24px;
      @include mixins.size($icon-size);

      svg {
        @include mixins.size($icon-size);
      }
    }
  }

  &-primary {
    @include mixins.button-color-interaction(
      neutral.$white_100,
      neutral.$white_100,
      neutral.$white_100
    );

    &:hover {
      @include mixins.text-with-svg-color(neutral.$white_100);
      background-color: accent.$accent_06;
    }
  }

  &-secondary {
    &:hover {
      @include mixins.text-with-svg-color(neutral.$neutral_06);
      background-color: neutral.$neutral_02;
    }
  }

  &-tertiary {
    @include mixins.button-color-interaction(
      neutral.$neutral_05,
      accent.$accent_05,
      accent.$accent_05
    );
    @include mixins.svg-fill(accent.$accent_05);
    @include mixins.typography-properties("accent-text-s");
    background-color: accent.$accent_02;
  }

  &-close {
    //background: url("/resources/icons/ic-cross-24.svg") no-repeat;
    transform: scale(0.5);
    opacity: 0.75;

    &:hover {
      opacity: 1;
    }
  }

  .text-with-icon {
    svg {
      display: block;
    }
  }
}
