$drop-shadow-position-positive-xs: 1px;
$drop-shadow-position-negative-xs: $drop-shadow-position-positive-xs * -1;
$drop-shadow-position-positive-s: 2px;
$drop-shadow-position-negative-s: $drop-shadow-position-positive-s * -1;
$drop-shadow-position-positive-m: 4px;
$drop-shadow-position-negative-m: $drop-shadow-position-positive-m * -1;

$drop-shadow-breath-xs: 2px;
$drop-shadow-breath-s: 7px;
$drop-shadow-breath-m: 12px;

$drop-shadow-opacity-dark: 0.2;
$drop-shadow-opacity-light: 0.15;
$drop-shadow-opacity-dark-xs: 0.3;
$drop-shadow-opacity-light-xs: 0.2;

// -- Drop Shadow --
$drop-shadow-down-xs-dark: 0px $drop-shadow-position-positive-xs $drop-shadow-breath-xs rgba(0, 0, 0, 0.3);
$drop-shadow-down-xs-light: 0px $drop-shadow-position-positive-xs $drop-shadow-breath-xs rgba(0, 0, 0, 0.2);
$drop-shadow-down-s-dark: 0px $drop-shadow-position-positive-s $drop-shadow-breath-s rgba(0, 0, 0, $drop-shadow-opacity-dark);
$drop-shadow-down-s-light: 0px $drop-shadow-position-positive-s $drop-shadow-breath-s rgba(0, 0, 0, $drop-shadow-opacity-light);
$drop-shadow-up-s-dark: 0px $drop-shadow-position-negative-s $drop-shadow-breath-s rgba(0, 0, 0, $drop-shadow-opacity-dark);
$drop-shadow-up-s-light: 0px $drop-shadow-position-negative-s $drop-shadow-breath-s rgba(0, 0, 0, $drop-shadow-opacity-light);
$drop-shadow-left-s-dark: $drop-shadow-position-negative-s 0 $drop-shadow-breath-s rgba(0, 0, 0, $drop-shadow-opacity-dark);
$drop-shadow-left-s-light: $drop-shadow-position-negative-s 0 $drop-shadow-breath-s rgba(0, 0, 0, $drop-shadow-opacity-light);
$drop-shadow-right-s-dark: $drop-shadow-position-positive-s 0 $drop-shadow-breath-s rgba(0, 0, 0, $drop-shadow-opacity-dark);
$drop-shadow-right-s-light: $drop-shadow-position-positive-s 0 $drop-shadow-breath-s rgba(0, 0, 0, $drop-shadow-opacity-light);
$drop-shadow-down-m-dark: 0px $drop-shadow-position-positive-m $drop-shadow-breath-m rgba(0, 0, 0, $drop-shadow-opacity-dark);
$drop-shadow-down-m-light: 0px $drop-shadow-position-positive-m $drop-shadow-breath-m rgba(0, 0, 0, $drop-shadow-opacity-light);
$drop-shadow-up-m-dark: 0px $drop-shadow-position-negative-m $drop-shadow-breath-m rgba(0, 0, 0, $drop-shadow-opacity-dark);
$drop-shadow-up-m-light: 0px $drop-shadow-position-negative-m $drop-shadow-breath-m rgba(0, 0, 0, $drop-shadow-opacity-light);
$drop-shadow-left-m-dark: $drop-shadow-position-negative-m 0 $drop-shadow-breath-m rgba(0, 0, 0, $drop-shadow-opacity-dark);
$drop-shadow-left-m-light: $drop-shadow-position-negative-m 0 $drop-shadow-breath-m rgba(0, 0, 0, $drop-shadow-opacity-light);
$drop-shadow-right-m-dark: $drop-shadow-position-positive-m 0 $drop-shadow-breath-m rgba(0, 0, 0, $drop-shadow-opacity-dark);
$drop-shadow-right-m-light: $drop-shadow-position-positive-m 0 $drop-shadow-breath-m rgba(0, 0, 0, $drop-shadow-opacity-light);
