@use "../../utilities/variables/spacing";
@use "../../utilities/variables/color/neutral";
@use "../../utilities/variables/text/fonts";
@use "../../utilities/mixins";
@use "../../utilities/functions" as utils;

@mixin tooltip-color($foreground, $background) {
  .tooltip-inner {
    background-color: $background;
    color: $foreground;
  }
  .tooltip-arrow {
    color: $background;
  }
}

.tooltip {
  $base: &;

  @include tooltip-color(neutral.$white_100, neutral.$neutral_06);
  filter: drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.2));

  .tooltip-inner {
    font-family: fonts.$gilroysemibold;
    line-height: utils.pxToRem(16);
  }

  &--coming-soon {
    @include tooltip-color(neutral.$white_100, neutral.$neutral_04);
  }

  &--route-data {
    @include tooltip-color(neutral.$neutral_06, neutral.$white_100);
  }

  &__trigger {
    display: inline-flex;

    &#{$base}--icon {
      margin-left: spacing.$spacing-m;
      @include mixins.size(utils.pxToRem(16));

      svg {
        pointer-events: none;
        vertical-align: unset;
      }
    }
  }
}
