@use "../../utilities/variables/color/neutral";
@use "../../utilities/variables/text/fonts";
@use "../../utilities/variables/spacing";
@use "../../utilities/mixins";

.rating {
  font-size: 14px;
  line-height: 18px;
  color: neutral.$neutral_04;
  font-family: fonts.$proxima;

  &__value {
    font-family: fonts.$gilroybold;
  }

  &__no-of-reviews {
    margin-left: spacing.$spacing-xxs;
  }
}
