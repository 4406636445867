@use "../utilities/variables/color/neutral";
@use "../utilities/variables/color/accent";
@use "../utilities/variables/color/status";
@use "../utilities/variables/spacing";
@use "../utilities/variables/dimentions";
@use "../utilities/mixins";

.control {
  &.has-icons-left,
  &.has-icons-right {
    .icon {
      svg {
        @include mixins.size(24px);
      }
    }
  }
}

textarea {
  &.form-control {
    height: unset !important;
    border-radius: 12px;
    resize: none;
    padding-top: spacing.$spacing-s !important;
    padding-bottom: spacing.$spacing-s !important;
  }
}

.form-floating {
  &.has-inner-left {
    & > label {
      margin-left: 36px;
    }
    & > .form-control:focus ~ label,
    & > .form-control:not(:placeholder-shown) ~ label,
    & > .form-select ~ label {
      transform: translateY(-1.2rem) translateX(-24px);
      border-radius: 10px;
    }
  }

  & > label {
    width: unset;
    height: unset;
    border: unset;
    padding: 0 spacing.$spacing-xxs;
    margin: 11px 0 11px 12px;
    background-color: neutral.$white_100;
  }

  & > .form-control:focus ~ label,
  & > .form-control:not(:placeholder-shown) ~ label,
  & > .form-select ~ label {
    @include mixins.typography-properties("body-xs");
    color: neutral.$neutral_06;
  }
}

.form-control {
  height: dimentions.$input-height;
  border-radius: 20px;
  @include mixins.typography-properties("accent-text");

  &:not(:placeholder-shown):not(:focus):not(.is-invalid):not(.is-valid) {
    border-color: accent.$accent_04;
  }

  &:hover:not(:focus):placeholder-shown {
    border-color: neutral.$neutral_04;
  }
}
