// --- SPACING ---

$spacing-xxxs: 2px;
$spacing-xxs: 4px;
$spacing-xs: 8px;
$spacing-s: 12px;
$spacing-m: 16px;
$spacing-l: 24px;
$spacing-xl: 32px;
$spacing-xxl: 40px;
$spacing-xxxl: 48px;
