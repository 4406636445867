$gilroybold: gilroybold, Helvetica, Arial, ui-sans-serif, sans-serif;
$gilroysemibold: gilroysemibold, Helvetica, Arial, ui-sans-serif, sans-serif;
$proxima: 'proxima_nova_regular', Helvetica, Arial, ui-sans-serif, sans-serif;

$font-weight: (
  "book": (
    font-family: $proxima
  ),
  "medium": (
    font-family: $gilroysemibold
  ),
  "bold": (
    font-family: $gilroybold
  )
);
