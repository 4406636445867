@use "../utilities/mixins";
@use "../utilities/functions";
@use "../utilities/variables/text/fonts";
@use "../utilities/variables/text/typography-usage";
@use "../utilities/variables/text/text-size";
@use "../utilities/variables/color/theme";

$fonts-folder: "../resources/ui-library/fonts/";

@font-face {
  font-family: gilroybold;
  font-style: normal;
  src: url($fonts-folder + 'gilroy-bold-webfont.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: gilroysemibold;
  font-style: normal;
  src: url($fonts-folder + 'gilroy-semibold-webfont.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'proxima_nova_regular';
  font-style: normal;
  src: url($fonts-folder + 'proximanova-regular-webfont.woff2') format('woff2');
  font-display: swap;
}

@mixin _typography-classes() {
  @each $type in map-keys(typography-usage.$typography-usage) {
    //@debug "!! mixin typographyAll >> " $type;
    .#{$type} {
      @include mixins.typography-properties($type);
    }

    //using level class instead of matching selectors in order to be able to nest
    //theme elements without increasing specificity
    @for $level from 1 through 4 {
      @include _typography-theme(
        $type,
        theme.$light-theme-class-name-map,
        "light",
        theme.$dark-theme-class-name-map,
        $level
      );
      @include _typography-theme(
        $type,
        theme.$dark-theme-class-name-map,
        "dark",
        theme.$light-theme-class-name-map,
        $level
      );
    }
  }
}

//themes a typography type taking into consideration nested themes
@mixin _typography-theme(
  $type,
  $theme-classes,
  $theme-name,
  $other-theme-classes,
  $level
) {
  $theme-classes-comma: functions.list-to-comma-seperated-string(
    $theme-classes
  );
  $other-theme-classes-comma: functions.list-to-comma-seperated-string(
    $other-theme-classes
  );

  @if ($level == 1) {
    :is(#{$theme-classes-comma}) {
      .#{$type} {
        @include mixins.typography-properties($type, $theme-name);
      }
    }
  } @else {
    .lv-#{$level}:is(#{$theme-classes-comma}) {
      .#{$type} {
        @include mixins.typography-properties($type, $theme-name);
      }
    }
  }
}

@mixin _font-size-classes() {
  @each $font-size in map-keys(text-size.$font-sizes) {
    //@debug "!! mixin fontSizeClasses >> " $font-size;
    .#{$font-size} {
      @include mixins.font-size-properties($font-size);
    }
  }
}

@mixin _font-weight-classes() {
  @each $font-weight in map-keys(fonts.$font-weight) {
    //@debug "!! mixin fontWeightClasses >> " $font-weight;
    .#{$font-weight} {
      @include mixins.property-value(map-get(fonts.$font-weight, $font-weight));
    }
  }
}

@include _typography-classes();
@include _font-size-classes();
@include _font-weight-classes();
