// --- ACCENT COLORS ---

$accent_01: #F3F9FD; // Light background
$accent_02: #DFEEF8; // Light background
$accent_03: #C5E1F3;
$accent_04: #8DC3EB; // Disabled primary
$accent_05: #3F9CD9; // Primary buttons, Active links, Active icons
$accent_06: #1988CF; // Hover primary
$accent_07: #0568A8; // Disabled on dark
$accent_08: #105287; // Dark background
$accent_09: #0A3653; // Dark background
