@use "../../utilities/variables/spacing";

.spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__message {
    margin-top: spacing.$spacing-s;
    &:empty {
      display: none;
    }
  }
}
