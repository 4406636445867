@use "../../utilities/variables/spacing";
@use "../../utilities/variables/effects";
@use "../../utilities/variables/text/fonts";
@use "../../utilities/variables/color/neutral";

// TODO We need to use react-based element for this. TTM has problems with loading svg's from scss
// $icons-folder: $base-folder + "/resources/icons/";

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y: spacing.$spacing-xxs;
$btn-padding-x: spacing.$spacing-m;
$btn-font-family: fonts.$gilroybold;
$btn-font-size: 14px;
$btn-line-height: 18px;

$btn-border-width: 0;

$btn-box-shadow: effects.$drop-shadow-down-s-dark;
$btn-active-box-shadow: effects.$drop-shadow-down-s-dark;

$btn-border-radius: 20px;

$btn-close-width: 24px;
// See todo above
// $btn-close-bg: url($icons-folder + "ic-cross-24.svg");
$btn-close-padding-x: 0;
