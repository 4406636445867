// --- NEUTRAL COLORS ---

$neutral_01: #F4F5F6;
$neutral_02: #D8DADD; // Text and icon in disabled button
$neutral_03: #BEC2C7; // Disabled states
$neutral_04: #727C85; // Subtitle, Placeholder text
$neutral_05: #3C4956;
$neutral_06: #263543; // Titles, Copy, Secondary button text
$neutral_07: #18212A; // Hover

$white_100: #ffffff; // Backgrounds
