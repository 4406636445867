@use "../../utilities/variables/spacing";

.badges {
  display: flex;
  flex-wrap: wrap;
}

.badge-group {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-right: spacing.$spacing-xs;
  margin-bottom: spacing.$spacing-xxs;

  &:last-child {
    margin-right: 0;
  }

  .badge {
    padding: spacing.$spacing-xxs spacing.$spacing-xs;

    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:not(:first-child) {
      margin-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
