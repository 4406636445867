@use "../utilities/mixins";
@use "../utilities/functions";
@use "../utilities/variables/color/button";
@use "../utilities/variables/color/neutral";
@use "../utilities/variables/color/accent";
@use "../utilities/variables/color/theme";
@use "../utilities/variables/spacing";
@use "../utilities/variables/dimentions";

body {
  margin: 0;
  border: none;
  -webkit-font-smoothing: subpixel-antialiased;
}

h5 {
  line-height: functions.pxToRem(20);
}

h6 {
  color: neutral.$neutral_04;
  line-height: functions.pxToRem(18);

  &.text-uppercase {
    font-size: functions.pxToRem(12);
    line-height: functions.pxToRem(16);
    letter-spacing: 0.01em;
  }
}

.icon {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  @include mixins.size(24px);
}

.has-inner-left,
.has-inner-right {
  position: relative;

  .icon,
  button {
    position: absolute;
    @include mixins.svg-fill(neutral.$neutral_04);
    top: 0;
    z-index: 4;
    @include mixins.size(
      dimentions.$form-icon-padding,
      dimentions.$input-height
    );

    &.is-left {
      left: 0;
      padding-left: spacing.$spacing-xs;
    }

    &.is-right {
      right: 0;
      padding-right: spacing.$spacing-xs;
    }

    &:hover {
      svg {
        path {
          fill: neutral.$neutral_06;
        }
      }
    }
  }

  .icon {
    pointer-events: none;
  }

  button {
    background: none;
    border: none;
    padding: 0;
    outline: none;
    line-height: 14px;
  }
}

.has-inner-left {
  input,
  textarea {
    padding-left: dimentions.$form-icon-padding !important;
  }
}

.has-inner-right {
  input,
  textarea {
    padding-right: dimentions.$form-icon-padding !important;
  }
}

.bg-light-darker {
  background-color: theme.$light-darker;
}

.bg-dark-lighter {
  background-color: theme.$dark-lighter;
}

.bg-base {
  background-color: neutral.$white_100;
}

.bg-accent {
  background-color: accent.$accent_05;
}
