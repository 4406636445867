@use "../../utilities/variables/spacing";
@use "../../utilities/variables/color/neutral";
@use "../../utilities/variables/color/accent";
@use "../../utilities/variables/text/fonts";
@use "../../utilities/variables/dimentions";
@use "../../utilities/functions" as utils;

// Forms

$input-btn-padding-y: 0;
$input-btn-padding-x: spacing.$spacing-m - 1px; //-1px for border

$input-disabled-bg: neutral.$white_100;
$input-disabled-border-color: neutral.$neutral_03;

$input-box-shadow: unset;
$input-placeholder-color: neutral.$neutral_04;
$input-border-color: neutral.$neutral_03;
$input-focus-border-color: accent.$accent_05;
$input-focus-width: 0;
$input-border-radius: 20px;

$form-floating-height: dimentions.$input-height;
$form-floating-padding-x: spacing.$spacing-m;
$form-floating-padding-y: spacing.$spacing-xs;
$form-floating-input-padding-t: 0;
$form-floating-input-padding-b: 0;
$form-floating-label-opacity: 1;
$form-floating-label-transform: translateY(-1rem);
$form-floating-transition: color 0.1s ease-in-out, font-size 0.1s ease-in-out,
  background 0.1s ease-in-out, opacity 0.1s ease-in-out,
  transform 0.1s ease-in-out;

// Form validation

$form-feedback-icon-valid: unset;
$form-feedback-icon-invalid: unset;
