@use "../../utilities/variables/color/neutral";
@use "../../utilities/variables/spacing";
@use "../../utilities/mixins";
@use "../../utilities/functions";

.expander {
  $base: &;
  background-color: neutral.$white_100;

  & & {
    padding: 0 spacing.$spacing-xl;

    #{$base}__body {
      padding: {
        left: 0;
        right: 0;
      }
    }
  }

  &.invisible-body .Collapsible__contentInner {
    display: none;
  }

  .Collapsible__trigger {
    display: flex;
    align-items: center;
    cursor: pointer;
    min-height: 56px;

    &:hover {
      svg {
        path {
          fill: neutral.$neutral_06;
        }
      }
    }

    .lists-item {
      padding-right: spacing.$spacing-s;

      .lists-item__icon--left {
        display: flex;
        align-items: center;
      }

      .lists-item__icon--right {
        margin-left: spacing.$spacing-xs;
      }

      .lists-item__icon--interactive {
        cursor: pointer !important;
      }
    }

    .lists-item--two-line .lists-item__icon--left + .lists-item__text {
      padding-top: 0;
    }
  }
}
