@use "../../utilities/variables/color/neutral";
@use "../../utilities/variables/text/fonts";
@use "../../utilities/variables/spacing";
@use "../../utilities/mixins";
@use "../../utilities/functions" as utils;

.card-heading {
  $base: &;

  &__title-area {
    display: flex;
    justify-content: space-between;
    margin-bottom: spacing.$spacing-xxs;
  }

  &__favorite {
    flex-shrink: 0;
    margin-left: spacing.$spacing-xxs;
    position: relative;
  }

  #{$base}__favorite {
    &:not(.disabled):not(.solid):hover {
      @include mixins.path-fill();
    }
    &.disabled {
      cursor: not-allowed;
      @include mixins.path-fill(neutral.$neutral_03);
    }
  }

  #{$base}__favorite-btn.active {
    @include mixins.svg-fill();
  }

  #{$base}__favorite-options {
    display: none;
    &--open {
      display: flex;
      flex-direction: column;
      position: fixed;
      left: 370px;
      top: 80px;
      border-radius: 12px;

      .lists-item:first-child {
        border-radius: 12px 12px 0 0;
      }

      .lists-item:last-child {
        border-radius: 0 0 12px 12px;
      }
    }
  }

  &--favorite-coming-soon {
    #{$base}__favorite {
      cursor: default;
      @include mixins.path-fill(neutral.$neutral_03);
    }
  }

  &--no-overflow {
    overflow: hidden;

    #{$base}__title,
    #{$base}__sub-title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &--small {
    $height: utils.pxToRem(20);

    #{$base}__favorite {
      height: $height;
      overflow: visible;
    }
  }
}
