@use "sass:math";
@use "../../utilities/variables/text/fonts";
@use "../../utilities/variables/text/text-size";
@use "../../utilities/variables/color/neutral";
@use "../../utilities/functions" as utils;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-base: fonts.$proxima;

$font-size-base: utils.pxToRem(text-size.$font-size-base);

$line-height-base: math.div(18, text-size.$font-size-base);

$h1-font-size: utils.pxToRem(34);
$h2-font-size: utils.pxToRem(28);
$h3-font-size: utils.pxToRem(24);
$h4-font-size: utils.pxToRem(20);
$h5-font-size: utils.pxToRem(16);
$h6-font-size: utils.pxToRem(14);

$headings-margin-bottom: 0;
$headings-font-family: fonts.$gilroysemibold;
$headings-font-weight: 400;
$headings-color: neutral.$neutral_06;
