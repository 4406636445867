@use "../../utilities/variables/color/neutral";
@use "../../utilities/variables/color/accent";
@use "../../utilities/variables/spacing";
@use "../../utilities/mixins";
@use "../../utilities/functions";
@use "../../utilities/variables/text/fonts";

.lists-item {
  $base: &;
  $top-padding: spacing.$spacing-xs;
  $icon-color: neutral.$neutral_04;

  background-color: neutral.$white_100;
  color: neutral.$neutral_06;
  padding: $top-padding spacing.$spacing-m;
  width: 100%;
  min-height: 40px;

  .list-two-line-text__title {
    word-break: break-word; //fallback for not supported overflow-wrap
    overflow-wrap: anywhere;
  }

  &--no-side-padding {
    padding: $top-padding 0;
  }

  &--compact,
  &--navigation {
    min-height: unset;
    #{$base}__text {
      padding: {
        top: unset;
        bottom: unset;
      }
    }
    #{$base}__icon {
      height: unset;
      width: unset;
      display: flex;
      align-self: center;

      &--left {
        margin-right: spacing.$spacing-xxs;
      }
    }
  }

  &--navigation {
    $color: neutral.$neutral_06;
    padding: $top-padding spacing.$spacing-s;

    #{$base}__icon {
      @include mixins.size(functions.pxToRem(16));
      @include mixins.svg-fill($color);
    }

    &:hover {
      $hover-color: accent.$accent_06;
      cursor: pointer;

      .list-two-line-text__title {
        color: $hover-color;
      }

      #{$base}__icon {
        @include mixins.svg-fill($hover-color);
      }
    }
  }

  &--interactive {
    cursor: pointer;

    &:hover {
      .list-two-line-text__title {
        color: neutral.$neutral_04;
      }
    }
  }

  &__trailing-text {
    color: neutral.$neutral_04;
    margin-left: spacing.$spacing-xxs;
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__text {
    cursor: inherit;
    flex-grow: 1;
    min-width: 0; //fixes long text from overflowing
    //calculating the top padding in order to align the text with icons
    padding-top: spacing.$spacing-m - spacing.$spacing-xxs - $top-padding;
    padding-bottom: spacing.$spacing-xxxs;

    @include mixins.unify-parent("label") {
      font-family: fonts.$proxima;
    }
    &[disabled] {
      cursor: not-allowed;
      color: neutral.$neutral_03;
    }
  }

  &--form {
    min-height: 36px;

    #{$base}__text {
      padding: 0;
    }

    #{$base}__control {
      margin: 0;
    }

    #{$base}__wrapper {
      cursor: pointer;
    }
  }

  &--subtitle {
    #{$base}__wrapper {
      border-bottom: 1px solid neutral.$neutral_03;
      padding-bottom: spacing.$spacing-xs;
    }

    #{$base}__control {
      margin-bottom: spacing.$spacing-xs;
    }
  }

  &--two-line {
    min-height: 60px;
    padding: spacing.$spacing-s spacing.$spacing-m;

    #{$base}__text {
      padding-top: 0;
      padding-bottom: 0;
    }

    #{$base}__icon {
      padding-top: spacing.$spacing-xxxs;
    }
  }

  &__icon {
    @include mixins.svg-fill($icon-color);
    @include mixins.size(functions.pxToRem(24));
    flex-shrink: 0;
    align-self: flex-start;

    &--left {
      margin-right: spacing.$spacing-xs;
    }

    &--right {
      margin-left: spacing.$spacing-s;
    }
  }

  &__control {
    margin-top: spacing.$spacing-xxs;
    margin-bottom: spacing.$spacing-xxs;

    &--left {
      order: -1;
      margin-right: 8px !important;
    }
  }

  .tooltip__trigger {
    vertical-align: text-bottom;
    @include mixins.svg-fill();
    opacity: 0.8;
    @include mixins.transition(opacity);

    &:hover {
      opacity: 1;
    }
  }

  &--clickable {
    $hover-color: accent.$accent_06;
    $active-color: accent.$accent_05;
    @include mixins.button-color-interaction();

    &:hover {
      .list-two-line-text__title {
        @include mixins.text-with-svg-color($hover-color);
      }
    }

    &:active {
      .list-two-line-text__title {
        @include mixins.text-with-svg-color($active-color);
      }
    }
  }

  a,
  a:hover {
    .icon-select__icon {
      @include mixins.svg-fill($icon-color);
    }
  }
}
