@use "../../utilities/variables/color/neutral";
@use "../../utilities/variables/color/accent";
@use "../../utilities/variables/spacing";
@use "../../utilities/variables/effects";
@use "../../utilities/variables/dimentions";

.panel {
  box-shadow: effects.$drop-shadow-down-s-light;
  width: 100%;
  border-radius: dimentions.$base-border-radius;
  background-color: neutral.$white_100;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  &__heading {
    min-height: 72px;
    display: flex;
    justify-content: space-between;
    padding: spacing.$spacing-l spacing.$spacing-m;
  }
}

.panel-return {
  display: flex;
  align-items: center;
  padding: spacing.$spacing-xs spacing.$spacing-m;
  color: inherit;
  cursor: pointer;

  &--contrast {
    background-color: neutral.$neutral_01;
  }

  &__icon {
    margin-right: spacing.$spacing-m;
  }
}
