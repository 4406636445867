@use "../../utilities/variables/spacing";
@use "../../utilities/variables/color/neutral";
@use "../../utilities/variables/color/accent";
@use "../../utilities/mixins";

.tabs {
  display: flex;
  flex-direction: column;

  &__menu {
    @include mixins.reset-list;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    flex-grow: 0;
    flex-shrink: 0;
    border-bottom: 1px solid neutral.$neutral_03;
  }

  &__content {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
  }

  &__tab {
    flex: 1;
  }
}

.tab {
  display: flex;
  justify-content: center;
  min-height: 40px;

  border: none;
  border-bottom: 2px solid transparent;
  flex-grow: 1;
  margin-bottom: -1px;

  &__action {
    flex-grow: 1;
    justify-content: center;
    &.is-container {
      padding: 0 spacing.$spacing-m !important;
    }
  }

  &--active {
    border-bottom-color: accent.$accent_06;
  }

  &--disabled#{&}--active {
    border-bottom-color: neutral.$neutral_03;
  }

  .icon-select {
    justify-content: center;
  }
}

.tab-panel {
  display: none;

  &--active {
    display: block;
  }
}
