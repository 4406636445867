@use "ui-library/utilities/variables/color/neutral";
@use "ui-library/utilities/variables/spacing";
@use "ui-library/utilities/mixins";
@use "ui-library/utilities/variables/transitions";
@use "ui-library/main.scss";
@use "utilities/variables/dimentions";

.page-container {
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  overflow: hidden;

  .notifications {
    z-index: 1;
    max-height: 100vh;
    margin-top: spacing.$spacing-s;

    a {
      color: inherit;
    }
  }
}

#map {
  height: 100vh;
}

.popup-no-tip {
  .maplibregl-popup-tip {
    display: none;
  }
}

.hidden {
  display: none;
}

.page-map {
  overflow: hidden;
  &--with-controls {
    .maplibregl-ctrl-bottom-right {
      -webkit-transform: translateX(-(dimentions.$map-options-width));
              transform: translateX(-(dimentions.$map-options-width));
    }
  }

  .maplibregl-ctrl-bottom-right {
    @include mixins.transition(transform, transitions.$transition-duration-xl);
  }
}

.distance-time {
  display: block;
  color: neutral.$neutral_04;
  margin-bottom: spacing.$spacing-xs;
  &__route-icon {
    margin-right: spacing.$spacing-xxs;
  }

  &__info {
    @include mixins.unify-parent("ul") {
      @include mixins.horizontal-list(neutral.$neutral_04);
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
    }
  }
}
