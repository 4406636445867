@use "../../utilities/variables/spacing";
@use "../../utilities/variables/color/accent";
@use "../../utilities/variables/color/neutral";
@use "../../utilities/variables/text/fonts";
@use "../../utilities/mixins";

.action-bar {
  $base: &;

  $columns: 3;
  display: grid;
  grid-template-columns: repeat($columns, auto);
  grid-column-gap: spacing.$spacing-xl;
  padding: spacing.$spacing-m spacing.$spacing-xxl;

  font-family: fonts.$gilroybold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.01em;

  &--big {
    $column-size: 64px;
    grid-template-columns: repeat($columns, $column-size);
    grid-column-gap: spacing.$spacing-xxxl;
    padding: spacing.$spacing-m spacing.$spacing-xl;

    font-family: fonts.$gilroysemibold;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: -0.02em;
  }

  &--secondary {
    #{$base}__action {
      $color: neutral.$neutral_04;
      &.btn {
        @include mixins.button-color-interaction($color);
      }
    }
  }
}
