@use "variables/color/accent";
@use "variables/color/neutral";
@use "variables/spacing";
@use "variables/text/text-size";
@use "variables/text/typography-usage";
@use "variables/transitions";
@use "sass:selector";

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin transition(
  $properties: all,
  $duration: transitions.$transition-duration-s,
  $timing-function: transitions.$transition-timing-function,
  $delay: transitions.$transition-delay
) {
  $value: ();
  @each $property in $properties {
    @if length($value) > 0 {
      $value: append(
        $value,
        unquote(",") #{$property} $duration $timing-function $delay
      );
    } @else {
      $value: append($value, #{$property} $duration $timing-function $delay);
    }
  }
  transition: $value;
}

@mixin active-menu-icon($has-interaction: false) {
  @if not $has-interaction {
    pointer-events: none;
  }
  background: #3f9cd9;

  svg {
    path {
      fill: #ffffff;
    }
  }
}

@mixin svg-fill($color: accent.$accent_05) {
  svg {
    @include path-fill($color);
  }
}

@mixin path-fill($color: accent.$accent_05) {
  path {
    fill: $color;
  }
}

@mixin button-as-container($set-cursor-pointer: true) {
  //reset style for when a button is used as a container with no styling of its own
  padding: 0;
  border: none;
  outline: none;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: initial;
  }
  &:disabled {
    background-color: transparent;
    pointer-events: none;
  }
  font: inherit;
  color: inherit;
  background-color: transparent;
  height: initial;
  width: initial;
  border-radius: initial;
  @if $set-cursor-pointer {
    cursor: pointer;
  }
}

@mixin button-color-interaction(
  $normal-color: neutral.$neutral_06,
  $hover-color: accent.$accent_06,
  $active-color: accent.$accent_05
) {
  cursor: pointer;
  @include text-with-svg-color($normal-color);
  &:hover {
    @include text-with-svg-color($hover-color);
  }
  &:active {
    @include text-with-svg-color($active-color);
  }
  &[disabled] {
    @include button-disabled;
  }
}

@mixin button-disabled($disabled-color: neutral.$neutral_02) {
  @include text-with-svg-color($disabled-color);
  cursor: not-allowed;
  pointer-events: none;
}

@mixin text-with-svg-color($color) {
  color: $color;
  @include svg-fill($color);
}

@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

@mixin horizontal-list($separator-color: accent.$accent_05) {
  @if (selector.is-superselector("ul", &)) {
    @include reset-list;
  } @else {
    ul {
      @include reset-list;
    }
  }

  li {
    display: inline-flex;
    position: relative;
    padding-left: spacing.$spacing-m + 5;

    &:not(:first-child):before {
      content: "";
      position: absolute;
      @include size(5px);
      background-color: $separator-color;
      border-radius: 50%;
      left: spacing.$spacing-xs;
      @include vertical-center();
    }

    &:first-child {
      padding-left: 0;
    }
  }
}

@mixin vertical-center() {
  top: 50%;
  transform: translateY(-50%);
}

//used to counter bulma's "block" mixin
@mixin not-block() {
  margin-bottom: 0;
}

@mixin unify-parent($child) {
  @at-root #{selector.unify(&, $child)} {
    @content;
  }
}

@mixin variant-parent($selector, $joined: false) {
  $space: " ";
  @if ($joined) {
    $space: "";
  }

  @at-root #{$selector}#{$space}#{&} {
    @content;
  }
}

// ---- Typography ----
@mixin property-value($list) {
  @each $property, $value in $list {
    //using #{$abc} in order to output the values
    #{$property}: #{$value};
  }
}

@mixin typography-properties($type, $color: "light") {
  @include property-value(
    map-get(typography-usage.$typography-usage, $type, $color)
  );
}

@mixin font-size-properties($fontSize) {
  @include property-value(
    map-get(text-size.$font-sizes, $fontSize, properties)
  );
}
